import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material";
import {ApiProviderService} from '../providers/api-provider.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  notification = {
    title : "",
    message : ""
  }

  constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>) { }

  ngOnInit() {
  }

  sendNotification() {
    this.dialogRef.close({
      title : this.notification.title,
      message : this.notification.message
    });
  }

}
