import { Component } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ContestsComponent } from './contests/contests.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'app';
  loggedIn = false;
  private user = null;

  constructor(private router: Router) {
    var checkLogin = () => {
      if (window.localStorage.getItem("access_token")) {
        if (this.loggedIn == false) {
          this.router.navigate(['/contests']);
        }
        this.loggedIn = true;
        this.user = JSON.parse(window.localStorage.getItem("user"));
      } else {
        this.loggedIn = false;
        this.router.navigate(['/login']); 
      }
      setTimeout(checkLogin, 1500);
    }
    checkLogin();
  }

  performLogout() {
    window.localStorage.removeItem("access_token");
  }   

  ngOnInit() {
  }  
}
