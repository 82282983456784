import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ContestsComponent } from './contests/contests.component';
import { ContestDetailViewComponent } from './contest-detail-view/contest-detail-view.component';
import { PriceDetailViewComponent } from './price-detail-view/price-detail-view.component';
import { NewsComponent } from './news/news.component';
import { CreateNewsComponent } from './create-news/create-news.component';
import { UsersComponent } from './users/users.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { UserDetailViewComponent } from './user-detail-view/user-detail-view.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'contests', component: ContestsComponent },
  { path: 'contest-detail-view', component: ContestDetailViewComponent},
  { path: 'price-detail-view', component: PriceDetailViewComponent},
  { path: 'news', component: NewsComponent},
  { path: 'create-news', component: CreateNewsComponent},
  { path: 'users', component: UsersComponent},
  { path: 'user-detail-view', component: UserDetailViewComponent},
  { path: 'reports', component: ReportsComponent},
  { path: 'settings', component: SettingsComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}