import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContestDetailViewComponent } from '../contest-detail-view/contest-detail-view.component';
import { ApiProviderService } from '../providers/api-provider.service';

@Component({
  selector: 'app-contests',
  templateUrl: './contests.component.html',
  styleUrls: ['./contests.component.scss']
})
export class ContestsComponent implements OnInit {

  public searchValue = "";
  public resultContests = [];
  public selected = 0;

  constructor(public router: Router, public apiService: ApiProviderService) {     
    this.search();
  }

  ngOnInit() {

  }

  search() {
    this.apiService.search(this.searchValue).then((result) => {
      this.resultContests = (result as any);
    }).catch((error) => {
      console.log(error);
    });
  }

  editContest(contest) {
    this.router.navigate(['/contest-detail-view'], {
      queryParams: {
        id: contest._id
      }
    });
  }

  newContest() {
    this.router.navigate(['/contest-detail-view'])
  }

  contestSelection() {
    for (var i=0;i<this.resultContests.length;i++){

    }
  }
}
