import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';

import { AppComponent } from './app.component';
import { ContestsComponent } from './contests/contests.component';
import { ContestDetailViewComponent } from './contest-detail-view/contest-detail-view.component';
import { LoginComponent } from './login/login.component';

//Angular Material
import {MatNativeDateModule, MatSliderModule, DateAdapter} from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './/app-routing.module';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { ApiProviderService } from './providers/api-provider.service';
import { MatIconModule } from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FileHelpersModule } from 'ngx-file-helpers';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { AuthHttp, AuthConfig } from 'angular2-jwt';
import { Http, HttpModule } from '@angular/http';
import { PriceDetailViewComponent } from './price-detail-view/price-detail-view.component';
import { NewsComponent } from './news/news.component';
import { NewsFilterPipe } from './pipes/news-filter-pipe';
import { CreateNewsComponent } from './create-news/create-news.component';

import { UsersComponent } from './users/users.component';
import { UserDetailViewComponent } from './user-detail-view/user-detail-view.component';
import { WinnerDialogComponent } from './winner-dialog/winner-dialog.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { WinnerDialogVoteComponent } from './winner-dialog-vote/winner-dialog-vote.component';
import { WinnerDialogJuryComponent } from './winner-dialog-jury/winner-dialog-jury.component';
import { ReportsComponent } from './reports/reports.component';
import { ContestFilterPipe } from './pipes/contest-filter-pipe';

//dragula
import { DragulaModule } from 'ng2-dragula';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { SettingsComponent } from './settings/settings.component';

export function getAuthHttp(http) {
  return new AuthHttp(new AuthConfig({
    noJwtError: true,
    globalHeaders: [{ 'Accept': 'application/json' }],
    tokenGetter: (() => window.localStorage.getItem('access_token')),
  }), http);
}

@NgModule({
  declarations: [
    AppComponent,
    ContestsComponent,
    ContestDetailViewComponent,
    LoginComponent,
    PriceDetailViewComponent,
    NewsComponent,
    NewsFilterPipe,
    CreateNewsComponent,
    UsersComponent,
    UserDetailViewComponent,
    WinnerDialogComponent,
    NotificationDialogComponent,
    WinnerDialogVoteComponent,
    WinnerDialogJuryComponent,
    ReportsComponent,
    ContestFilterPipe,
    DeleteDialogComponent,
    SettingsComponent
  ],
  entryComponents: [
    WinnerDialogComponent,
    NotificationDialogComponent,
    WinnerDialogVoteComponent,
    WinnerDialogJuryComponent,
    DeleteDialogComponent
],
  imports: [
    FormsModule,
    BrowserModule,
    MatFormFieldModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatInputModule,
    MatButtonModule,
    HttpModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,  
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    FileHelpersModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    MatChipsModule,
    MatCheckboxModule,
    DragulaModule.forRoot()
  ],
  providers: [    ApiProviderService,
    {
      provide: AuthHttp,
      useFactory: getAuthHttp,
      deps: [Http]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
