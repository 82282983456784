import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiProviderService } from '../providers/api-provider.service';

@Component({
  selector: 'app-user-detail-view',
  templateUrl: './user-detail-view.component.html',
  styleUrls: ['./user-detail-view.component.scss']
})
export class UserDetailViewComponent implements OnInit {

  user = {
    _id : "",
    profilePicture : "",
    username : "",
    follower : "",
    following : "",
    monthlypoints : "",
    number_of_submissions : "",
    total_votes : "",
    meta : ""
  }

  constructor(private route: ActivatedRoute, private router: Router, private apiService : ApiProviderService) { 
    this.route.queryParams.subscribe(params => {
        this.apiService.getUserById(params['id']).then(data => {
          console.log(data);
          this.user = (data as any);
        }).catch((err) => {
          console.log(err);
        })
      });
  }

  ngOnInit() {
  }

  safe() {

  }

  deleteUser(){

  }

}
