import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: 'app-winner-dialog-jury',
  templateUrl: './winner-dialog-jury.component.html',
  styleUrls: ['./winner-dialog-jury.component.scss']
})
export class WinnerDialogJuryComponent implements OnInit {

  choosenUser: string;
  resultUsers = [];
  searchValue = "";

  submissions = {};

  constructor(public dialogRef: MatDialogRef<WinnerDialogJuryComponent>,  @Inject(MAT_DIALOG_DATA) private data: { submissions: any }) {
    this.submissions = this.data.submissions;
   }

  ngOnInit() {
  }

  chooseWinner(item) {
    this.dialogRef.close({
      winnerId : item.objectID
    });
  }

}
