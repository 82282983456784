import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: 'app-winner-dialog-vote',
  templateUrl: './winner-dialog-vote.component.html',
  styleUrls: ['./winner-dialog-vote.component.scss']
})
export class WinnerDialogVoteComponent implements OnInit {

  winnerSubmission = {};

  constructor(public dialogRef: MatDialogRef<WinnerDialogVoteComponent>, @Inject(MAT_DIALOG_DATA) private data: { winner: any }) 
  { 
    this.winnerSubmission = this.data.winner;
  }

  ngOnInit() {
  }

}
