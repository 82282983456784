import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiProviderService } from '../providers/api-provider.service';
import { FilePickerDirective, ReadFile, ReadMode } from 'ngx-file-helpers';

@Component({
  selector: 'app-create-news',
  templateUrl: './create-news.component.html',
  styleUrls: ['./create-news.component.scss']
})

export class CreateNewsComponent implements OnInit {

  winnerSubmission : any;

  public news = {
    title: "",
    subtitle: "",
    headerImage: "",
    content: [],
    creationDate: 0
  };
  public errorlist = [];
  public newNews = false;
  public editMode = false;
  private contentblocks = "contentblocks";
  types = ["image", "text", "submission", "contest", "profil"]
  openPanel = 1;

  constructor(private apiService: ApiProviderService, private route: ActivatedRoute, private router: Router) {

    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.contentblocks = "contentblocks-" + params['id'];
        this.apiService.getNewsById(params['id']).then(data => {
          this.news = (data as any);
          this.editMode = true;
          //open Panel Wahl
          if (!(this.news.title == "")) {
            this.openPanel = 3;
          }          
        }).catch((err) => {
          console.log(err);
        })
      }
      else {
        this.newNews = true;
        this.newData();
      }
    });
  }

  ngOnInit() {
  }

  loadSubmission(winnerId) {
    console.log(winnerId);
          this.apiService.loadSubmissionWithId(winnerId).then((submission) => {
            this.winnerSubmission = submission;
          }).catch((err) => {
            console.log(err);
          });
  }

  //Add HeaderImage
  addFileHead(file: ReadFile) {
    this.uploadHeadImage(file.content);
  }

  onFilePickedHead(event) {
    this.uploadHeadImage(event.content);
  }

  uploadHeadImage(news) {
    this.uploadImage(news).then((url) => {
      this.news.headerImage = (url as any);
    }).catch((err) => {
      console.log(err);
    });;
  }
  //delete HeaderImage
  undoUploadHeader() {
    this.news.headerImage = "";
  }

  //Upload Image
  uploadImage(data) {
    var fileToUpload = {
      filename: this.randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'),
      filetype: "image/png",
      data: data
    }
    return new Promise((resolve, reject) => {
      this.apiService.uploadImage(fileToUpload).then((fileurl) => {
        resolve((fileurl as any).url);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  newData() {
    this.news.content.push({
      "conentType": "",
      "value": ""
    });
  }

  deleteData(data) {
    for (var i = 0; i < this.news.content.length; i++) {
      if (data.types == this.news.content[i].types && data.value == this.news.content[i].value) {
        this.news.content.splice(i, 1);
      }
    }
  }

  saveNews() {
    this.errorlist = [];
    if (this.news.title == "") {
      this.errorlist.push("Name fehlt");
    }
    if (this.news.subtitle == "") {
      this.errorlist.push("subtitle fehlt");
    }
    if (this.news.headerImage == "") {
      this.errorlist.push("headerImage fehlt");
    }
    for(var i =0;i<this.news.content.length;i++){
      if (this.news.content[i].types==""){
        this.errorlist.push("Content type fehlt");
      }
      if (this.news.content[i].value==""){
        this.errorlist.push("Content value fehlt");
      }
    }
    if (this.errorlist.length > 0) {
      console.log(this.errorlist);
      return;
    }


    if (this.newNews == true) {
      this.news.creationDate = Date.now();
      this.apiService.createNews(this.news).then((storedNews) => {
      }).catch(err => console.log(err));
    }
    else {
      this.apiService.updateNews(this.news).then((storedNews) => {
      }).catch(err => console.log(err));
    }
    this.router.navigate(['/news']);
  }

  deleteNews() {
    this.apiService.deleteNews(this.news).then((storedNews) => {
    }).catch(err => console.log(err));
    this.router.navigate(['/news']);
  }
}