import { Pipe, PipeTransform } from '@angular/core';
import { UpperCasePipe } from '@angular/common';

@Pipe({
    name: 'ContestFilterPipe',
    pure: false
})

export class ContestFilterPipe implements PipeTransform {
    transform(items: any[], filter: number): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out

        //Alle Contests
        if (filter==0){ 
            return items;
        }
        //Laufende Contests 
        if (filter==1){
            return items.filter(item =>  new Date(item.dueTo) > new Date(Date.now()));
        }
        //Zu Berwertende Contests
        if (filter==2){
            return items.filter(item =>  new Date(item.dueTo) < new Date(Date.now()) && item.completed == false);
        }
        //Abgeschlossene Contests
        if (filter==3){
            return items.filter(item =>  item.completed == true);         
        }        
    }
}