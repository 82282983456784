import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiProviderService } from '../providers/api-provider.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  constructor(public router: Router, public apiService: ApiProviderService) { 
    this.getNews();
  }

  public searchValue = "";
  public resultNews = [];

  ngOnInit() {
    this.getNews();
  }

  getNews() {
    this.apiService.getNews().then((result) => {
      this.resultNews = (result as any);
    }).catch((error) => {
      console.log(error);
    });
  }

  editNews(news) {
    this.router.navigate(['/create-news'], {
      queryParams: {
        id: news._id
      }
    });
  }

  newNews(){
    this.router.navigate(['/create-news'])
  }
}
