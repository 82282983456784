import { Component, OnInit } from '@angular/core';
import { Router } from '../../../node_modules/@angular/router';
import { ApiProviderService } from '../providers/api-provider.service';
import { FilePickerDirective, ReadFile, ReadMode } from 'ngx-file-helpers';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public router: Router, public apiService: ApiProviderService) { }

  settings = {
    dailytheme: "",
    monthlyPriceName: "",
    monthlyPriceUrl: "",
  };

  ngOnInit() {
    this.getSettings();
  }

  getSettings() {
    this.apiService.getGlobalSettings().then((settings) => {
      this.settings = (settings as any);
    }).catch((error) => {
      console.log(error);
    });
  }

  setSettings() {
    this.apiService.setGlobalSettings(this.settings).then((settings) => {
    }).catch(err => console.log(err));
  }

  save() {
    this.setSettings();
    this.router.navigate(['/contests']);
  }

    //Add LogoIcon
    addFileIcon(file: ReadFile) {
      this.uploadMonthlyPriceImage(file.content);
    }
  
    uploadMonthlyPriceImage(content) {
      this.uploadImage(content).then((url) => {
        this.settings.monthlyPriceUrl = (url as any);
      }).catch((err) => {
        console.log(err);
      });;
    }

    onFilePickedIcon(event) {
      this.uploadMonthlyPriceImage(event.content);
    }
  
    //Delete LogoImage
    undoUploadIcon() {
      this.settings.monthlyPriceUrl = "";
    }

      //Upload Image
  uploadImage(data) {
    var fileToUpload = {
      filename: this.randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'),
      filetype: "image/png",
      data: data
    }
    return new Promise((resolve, reject) => {
      this.apiService.uploadImage(fileToUpload).then((fileurl) => {
        resolve((fileurl as any).url);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
