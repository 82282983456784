import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
@Component({
  selector: 'app-winner-dialog',
  templateUrl: './winner-dialog.component.html',
  styleUrls: ['./winner-dialog.component.scss']
})
export class WinnerDialogComponent implements OnInit {

  winnerSubmission = {};

  constructor(public dialogRef: MatDialogRef<WinnerDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { winner: any }) {
    this.winnerSubmission = this.data.winner;
    console.log(JSON.stringify(this.winnerSubmission));
   }

  ngOnInit() {}


}
