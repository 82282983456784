import { Component, OnInit } from '@angular/core';
import { ApiProviderService } from '../providers/api-provider.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {

  public reports = [];

  constructor(public apiService: ApiProviderService) { }

  ngOnInit() {
    this.getReports();
  }

  getReports() {
    this.apiService.getReports().then((fetchedReports) => {
      this.reports = (fetchedReports as any);
      console.log(this.reports);
      console.log(this.reports[0].submission.media.url)
    }).catch((error) => {
      console.log(error);
    });
  }

  ignor() {
    if (this.reports.length == 0) {
      return;
    }
    if (this.reports[0].reporttype == "submission") {
      this.apiService.deleteReport(this.reports[0]).then((submission) => {
        this.reports.shift();
      }).catch((err) => {
        console.log(err);
      });;
    }
  }

  delete() {
    if (this.reports.length == 0) {
      return;
    }
    if (this.reports[0].reporttype == "submission") {
      this.apiService.deleteSubmission(this.reports[0]).then((submission) => {
      }).catch((err) => {
        console.log(err);
      });;
      this.apiService.deleteReport(this.reports[0]).then((submission) => {
        this.reports.shift();
      }).catch((err) => {
        console.log(err);
      });;
    }
  }
}