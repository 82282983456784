import { Component, OnInit } from '@angular/core';
import { ApiProviderService } from '../providers/api-provider.service';
import { Router }  from '@angular/router';
import { stripGeneratedFileSuffix } from '@angular/compiler/src/aot/util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public email = "";
  public password = "";
  public login_error = false;

  constructor(private apiService: ApiProviderService, private router: Router) { }
  login() {
    this.apiService.login(this.email, this.password).then((user) => {
      if(user) {
        this.router.navigate(['/contests']);
        this.login_error = false;
      }
    }).catch((error) => {
      console.log("Error?" + error)
      this.login_error = true;
    });
  }

  ngOnInit() {
  }

}