import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ApiProviderService } from '../providers/api-provider.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FilePickerDirective, ReadFile, ReadMode } from 'ngx-file-helpers';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { WinnerDialogComponent } from '../winner-dialog/winner-dialog.component';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { WinnerDialogVoteComponent } from '../winner-dialog-vote/winner-dialog-vote.component';
import { WinnerDialogJuryComponent } from '../winner-dialog-jury/winner-dialog-jury.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

declare var google: any;

@Component({
  selector: 'app-contest-detail-view',
  templateUrl: './contest-detail-view.component.html',
  styleUrls: ['./contest-detail-view.component.scss']
})
export class ContestDetailViewComponent implements OnInit {

  selectedUser: string;

  notificationTitle = "";
  notificationMessage = "";

  public editMode = false;

  //values
  public contest = {
    _id: "",
    name: "",
    headerImage: "",
    logoImage: "",
    creationDate: new Date(),
    startDate: new Date(),
    dueTo: new Date(),
    _geoloc: {
      lat: 0.0,
      lng: 0.0
    },
    location: {
      type: "Point",
      coordinates: []
    },
    locationName: "",
    description: "",
    termsOfService: "",
    homepage: "",
    prices: [],
    completed: false,
    companyName: "",
    termsOfAcceptance: ""
  };

  dateError = false;
  openPanelDetail = false;
  openPanelImage = false;
  openPanelPrice = false;
  winnerChoosed = true;
  errorlist = [];
  public submissions = [];
  public modes = ["jury", "vote", "lucky"];
  private priceblocks = "priceblocks";

  //autocomplete
  autocomplete: any;
  GoogleAutocomplete: any;
  GooglePlaces: any;
  geocoder: any
  autocompleteItems: any;
  nearbyItems: any = new Array<any>();
  loading: any;
  public resultContest = [];

  constructor(public zone: NgZone, private apiService: ApiProviderService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {


    //Load data from Server
    /*
            this.apiService.getContestById('5b3cf7997bebc100045fca03').then(data => {
              this.contest = (data as any);
              this.editMode = true;
            }).catch((err) => {
              console.log(err);
            })
    */

    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.apiService.getContestById(params['id']).then(data => {
          this.contest = (data as any);
          this.editMode = true;
          this.autocomplete.input = this.contest.locationName;
          this.openPanelPrice = true;
          this.apiService.getSubmissions(this.contest._id).then(submissions => {
            console.log(submissions);
            this.submissions = (submissions as any[]);
          })
          console.log();
        }).catch((err) => {
          console.log(err);
        })
      }
      else{
        this.addPrice();
        this.openPanelDetail = true;
      }
    });

    //Autocomplete places
    this.geocoder = new google.maps.Geocoder;
    let elem = document.createElement("div");
    this.GooglePlaces = new google.maps.places.PlacesService(elem);
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.autocomplete = {
      input: ''
    };
    this.autocompleteItems = [];

    this.allWinnerChoosed();
  }

  ngOnInit() {
  }

  //Maps Autocomplete
  updateSearchResults() {
    if (this.autocomplete.input == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({ input: this.autocomplete.input },
      (predictions, status) => {
        this.autocompleteItems = [];
        if (predictions) {
          this.zone.run(() => {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          });
        }
      });
  }

  //Select maps Autocomplete
  selectSearchResult(item) {
    this.autocompleteItems = [];
    this.geocoder.geocode({ 'placeId': item.place_id }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.autocompleteItems = [];
        this.contest._geoloc.lat = results[0].geometry.location.lat();
        this.contest._geoloc.lng = results[0].geometry.location.lng();
        this.contest.locationName = results[0].formatted_address;
        this.autocomplete.input = results[0].formatted_address;
        console.log(this.autocomplete.input);
        this.GooglePlaces.nearbySearch({
          location: results[0].geometry.location,
          radius: '500',
        }, (near_places) => {
          this.zone.run(() => {
            this.nearbyItems = [];
            for (var i = 0; i < near_places.length; i++) {
              this.nearbyItems.push(near_places[i]);
            }
          });
        });
      }
    });
  }

  //Button safe clicked
  save() {
    this.openPanelDetail = false;
    this.openPanelImage = false;
    this.openPanelPrice = false;

    this.errorlist = [];
    if (this.contest.name == "") {
      this.errorlist.push("Name fehlt");
      this.openPanelDetail = true;
    }
    if (this.contest.companyName == "") {
      this.errorlist.push("Companyname fehlt");
      this.openPanelDetail = true;
    }
    if (this.contest.termsOfAcceptance == "") {
      this.errorlist.push("Winning criteria fehlt");
      this.openPanelDetail = true;
    }
    if (this.contest.description == "") {
      this.errorlist.push("description fehlt");
      this.openPanelDetail = true;
    }
    if (this.contest.termsOfService == "") {
      this.errorlist.push("Terms of Service fehlt");
      this.openPanelDetail = true;
    }
    if (this.contest.homepage == "") {
      this.errorlist.push("homepage fehlt");
      this.openPanelDetail = true;
    }
    if (this.contest.location.coordinates[0] == 0) {
      this.errorlist.push("location fehlt");
      this.openPanelDetail = true;
    }
    if (this.contest.dueTo <= this.contest.startDate) {
      this.errorlist.push("Enddatum ist vor dem Startdatum");
      this.dateError = true;
      this.openPanelDetail = true;
    }
    if (this.contest.logoImage == "") {
      this.errorlist.push("Logoimage fehlt");
      this.openPanelImage = true;
    }
    if (this.contest.headerImage == "") {
      this.errorlist.push("Headerimage fehlt");
      this.openPanelImage = true;
    }
    for(var i =0;i<this.contest.prices.length;i++){
      if (this.contest.prices[i].name==""){
        this.errorlist.push("Price name fehlt");
        this.openPanelPrice = true;
      }
      if (this.contest.prices[i].description==""){
        this.errorlist.push("Price discription fehlt");
        this.openPanelPrice = true;
      }
      if (this.contest.prices[i].modus==""){
        this.errorlist.push("Price modus fehlt");
        this.openPanelPrice = true;
      }
      if (this.contest.prices[i].image==""){
        this.errorlist.push("Price image fehlt");
        this.openPanelPrice = true;
      }
    }
    if (this.errorlist.length > 0) {
      console.log(this.errorlist);
      return;
    }

    //safe contest
    var contest = JSON.parse(JSON.stringify(this.contest));
    contest.prices.forEach(price => {
      delete price.winner;
    });
    this.contest.location = {
      type: "Point",
      coordinates: [this.contest._geoloc.lng, this.contest._geoloc.lat]
    };
    this.apiService.saveContest(this.contest).then((result) => {
      this.editMode = true;
    }).catch((err) => {
      console.log(err);
    });

    this.router.navigate(['/contests']);
  }

  //Add LogoIcon
  addFileIcon(file: ReadFile) {
    this.uploadIcon(file.content);
  }

  uploadIcon(content) {
    this.uploadImage(content).then((url) => {
      this.contest.logoImage = (url as any);
    }).catch((err) => {
      console.log(err);
    });;
  }

  onFilePickedIcon(event) {
    this.uploadIcon(event.content);
  }

  //Delete LogoImage
  undoUploadIcon() {
    this.contest.logoImage = "";
  }

  //Add HeaderImage
  addFileHead(file: ReadFile) {
    this.uploadHeadImage(file.content);
  }

  uploadHeadImage(content) {
    this.uploadImage(content).then((url) => {
      this.contest.headerImage = (url as any);
    }).catch((err) => {
      console.log(err);
    });;
  }

  onFilePickedHead(event) {
    this.uploadHeadImage(event.content);
  }

  //delete HeaderImage
  undoUploadHeader() {
    this.contest.headerImage = "";
  }

  //Add PriceImage
  uploadPriceImage(file: ReadFile, price) {
    this.uploadPriceImageFunc(file.content, price);
  }

  onFilePickedPriceImage(event, price) {
    this.uploadPriceImageFunc(event.content, price);
  }

  uploadPriceImageFunc(content, price) {
    this.uploadImage(content).then((url) => {
      price.image = (url as any);
    }).catch((err) => {
      console.log(err);
    });;
  }

  //Upload Image
  uploadImage(data) {
    var fileToUpload = {
      filename: this.randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'),
      filetype: "image/png",
      data: data
    }
    return new Promise((resolve, reject) => {
      this.apiService.uploadImage(fileToUpload).then((fileurl) => {
        resolve((fileurl as any).url);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  //delete Contest
  deleteContest() {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.apiService.deleteContest(params['id']).then(data => {
        }).catch((err) => {
          console.log(err);
        })
      }
    });
    this.router.navigate(['/contests']);
  }

  editPrice(price) {
    this.router.navigate(['/price-detail-view'], {
      queryParams: {
        id: price._id
      }
    });
  }

  addPrice() {
    this.contest.prices.push({
      name: "",
      modus: "",
      image: "",
      description: "",
      winner: ""
    });
    this.allWinnerChoosed();
  }

  //Winner dialog
  openWinnerDialog(price) {
    //Nothing choosen
    if (price.modus == "") {
      alert("Keine Preismodus gewählt");
    }

    //Jury
    if (price.modus == "jury") {
      let dialog = this.dialog.open(WinnerDialogJuryComponent, {
        data: {
          submissions: this.submissions
        }
      });

      dialog.afterClosed().subscribe(data => {
        if (data) {
          price.winner = data.winnerId;
          this.allWinnerChoosed();
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
    }

    //Lucky
    if (price.modus == "lucky") {
      //Random generator
      var randomNumber = Math.floor(Math.random() * (this.submissions as any).length);


      let dialog = this.dialog.open(WinnerDialogComponent, {
        data: {
          winner: this.submissions[randomNumber]
        }
      });
      price.winner = this.submissions[randomNumber].objectID;
    }

    //Vote
    if (price.modus == "vote") {
      var mostVotes = this.submissions[0];
      for (var i = 0; i < (this.submissions as any).length; i++) {
        if (this.submissions[i].votes_count > mostVotes.votes_count) {
          mostVotes = this.submissions[i];
        }
      }
      price.winner = mostVotes.objectID;
      console.log("Most Votes: " + JSON.stringify(mostVotes));
      let dialog = this.dialog.open(WinnerDialogVoteComponent, {
        data: {
          winner: mostVotes
        }
      });
    }
    this.allWinnerChoosed();
  }

  //Notification dialog
  openNotificationDialog() {
    let dialog = this.dialog.open(NotificationDialogComponent);

    dialog.afterClosed()
      .subscribe(data => {
        if (data) {
          console.log(data);
          this.notificationTitle = data.title;
          this.notificationMessage = data.message;
          console.log(this.notificationMessage);
          //this.sendNotification();
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
    this.sendNotification();
  }

  sendNotification() {
    this.apiService.sendNotification(this.contest._id, {
      title: this.notificationTitle,
      message: this.notificationMessage
    }).then((result) => {
      this.notificationMessage = "";
    }).catch((err) => {
      console.log(err);
    });
  }

  //Complete contest
  notifyWinnerAnnouncement() {
    this.apiService.notifyContestWinners(this.contest._id).then((result) => {
    }).catch((err) => {
      console.log(err);
    });

    this.router.navigate(['/contests']);
  }

  deletePrice(price) {
    var prices = this.contest.prices;
    for (var i = 0; i < prices.length; i++) {
      if (prices[i].name == price.name && prices[i].description == price.description && prices[i].image == price.image) {
        this.contest.prices.splice(i, 1);
      }
    }
    this.allWinnerChoosed();
  }

  allWinnerChoosed() {
    console.log(this.contest.prices);
    this.winnerChoosed = true;
    if (this.contest.prices.length == 0) {
      this.winnerChoosed = false;
    }
    for (var i = 0; i < this.contest.prices.length; i++) {
      if (this.contest.prices[i].winner == "") {
        this.winnerChoosed = false;
      }
    }
  }

  priceModeChanged(price) {
    price.winner = "";
    this.winnerChoosed = false;
  }

  openDeleteDialog() {
    let dialog = this.dialog.open(DeleteDialogComponent);

    dialog.afterClosed()
      .subscribe(data => {
        if (data) {
          this.deleteContest();
        } else {
          
        }
      });
  }

  //deleteSubmission
  deleteSubmission(submissionId) {
    this.apiService.deleteSubmission(submissionId).then((submission) => {
    }).catch((err) => {
      console.log(err);
    });;
  }
}