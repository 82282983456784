import { Injectable } from '@angular/core';
import { AuthHttp } from 'angular2-jwt';

declare var window: any;

const APIMethod = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
};

@Injectable()
export class ApiProviderService {
  private apiURL = "https://api.crazywin.com/api/";
  //private apiURL = "http://localhost:4040/api/";

  constructor(public http: AuthHttp) {}


  performFetch(method, endpoint, data: any = {}) {
    var url = this.apiURL + endpoint;
    if (method == APIMethod.GET) {
      return this.http.get(url);
    } else if (method == APIMethod.POST) {
      return this.http.post(url, data);
    } else if (method == APIMethod.PUT) {
      return this.http.put(url, data);
    } else if (method == APIMethod.DELETE) {
      return this.http.delete(url);
    }
    return null;
  }

  /*
     Helper which injects the user token.
   */
  fetch(method, endpoint, data: any = {}) {
    return new Promise((resolve, reject) => {
      this.performFetch(method, endpoint, data).toPromise().then((data: any) => {
        var body = data._body;
        if (body) {
          var jsonResponse = JSON.parse(body);
          if (jsonResponse) {
            return resolve(jsonResponse);
          }
        }
        reject({});
      }).catch((error) => {
        reject(error);
      });
    });
  }

  login(email: String, password: String) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.POST, 'auth/login', {
        email: email,
        password: password
      }).then((data) => {
        var response = (data as any);
        let access_token = response.access_token;
        let user = response.user;
        if (access_token && user) {
          window.localStorage.setItem('access_token', access_token);
          window.localStorage.setItem('user', JSON.stringify(user));
          resolve(user);
        } else {
          reject("no data");
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  search(searchValue: String) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'contests?admin=true&filterType=all&name='+ searchValue).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getContestById(id: String) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'contests/' + id).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  sendNotification(id, message) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.POST, 'admin/sendcontestnotification/' + id, message).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  notifyContestWinners(id) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'admin/notifycontestwinners/' + id).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  saveContest(contest) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.POST, 'admin/contests', contest).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  uploadImage(file) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.POST, 'admin/fileupload', file).then((data) => {
        var response = (data as any);
        if (response) {
          resolve(response);
        } else {
          reject({});
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getReports() {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'reports').then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  deleteReport(report) {
    return new Promise((resolve, reject) => {
      return this.fetch(APIMethod.DELETE, 'reports/' + report._id).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err)
      });
    });
  }

  deleteComment(report) {
    return new Promise((resolve, reject) => {
      return this.fetch(APIMethod.DELETE, 'submissions/' + report.submission._id + "/" + report.comment).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err)
      });
    });
  }
  getComment(commentId) {
    let firebaseUrl = "https://crazywin-e9fcb.firebaseio.com/comments/5ab4f606be6591eec4e8b3b9/";
    return new Promise((resolve, reject) => {
      let key = window.localStorage.getItem('access_token');
      window.localStorage.setItem('access_token', "");
      return this.http.get(firebaseUrl + commentId + ".json").toPromise().then((data) => {
        window.localStorage.setItem('access_token', key);
        resolve((data as any)._body);
      }).catch(err => reject(err));
    });
  }
  deleteContest(contestId) {
    return new Promise((resolve, reject) => {
      return this.fetch(APIMethod.DELETE, 'contests/' + contestId).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err)
      });
    });
  }

  /** NEWS */
  createNews(news) {
    return new Promise((resolve, reject)=>{
      return this.fetch(APIMethod.POST, 'news', news).then((result)=>{
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }

  getNews() {
    return new Promise((resolve, reject)=>{
      return this.fetch(APIMethod.GET, 'news').then((result)=>{
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }

  getNewsById(id){
    return new Promise((resolve, reject)=>{
    console.log("ID"+ id);
      return this.fetch(APIMethod.GET, 'news/' + id).then((result)=>{
        console.log("result" + result);
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }

  updateNews(news) {
    return new Promise((resolve, reject)=>{
      return this.fetch(APIMethod.PUT, 'news/' + news._id, news).then((result)=>{
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }

  deleteNews(news){
    return new Promise((resolve, reject)=>{
      return this.fetch(APIMethod.DELETE, 'news/' + news._id).then((result)=>{
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }

  /** USER */
  getUsers() {
    return new Promise((resolve, reject)=>{
      return this.fetch(APIMethod.GET, 'users').then((result)=>{
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }

  searchUser(searchValue: String) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'users?username='+ searchValue).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getUserById(id: String) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'users/' + id).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /**SUBMISSIONS */
  getSubmissions(contestId) {
    return new Promise((resolve, reject)=>{
      return this.fetch(APIMethod.GET, 'contests/'+contestId+'/allSubmissions').then((result)=>{
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }

  deleteSubmission(report) {
    return new Promise((resolve, reject) => {
      return this.fetch(APIMethod.DELETE, 'submissions/' + report.submission._id).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err)
      });
    });
  }

  loadSubmissionWithId(id) {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'submissions/index/' + id, {}).then((data) => {
        if (data) {
          resolve(data);
        } else {
          reject({})
        }
      });
    });
  }

  getGlobalSettings() {
    return new Promise((resolve, reject) => {
      this.fetch(APIMethod.GET, 'admin/globalsettings', {}).then((data) => {
        if (data) {
          resolve(data);
        } else {
          reject({})
        }
      });
    });
  }

  setGlobalSettings(settings) {
    return new Promise((resolve, reject) => {
      return this.fetch(APIMethod.POST, 'admin/globalsettings', settings).then((result)=>{
        resolve(result);
      }).catch((err)=>{
        reject(err);
      });
    });
  }
}