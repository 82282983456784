import { Component, OnInit } from '@angular/core';
import {ApiProviderService} from '../providers/api-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  resultUsers = [];
  searchValue = "";

  constructor(private apiService : ApiProviderService, private router : Router) { }

  ngOnInit() {
    console.log(this.apiService.getUsers());
    this.search();
  }

  search(){
    this.apiService.searchUser(this.searchValue).then((result) => {
      this.resultUsers = (result as any);
      console.log(this.resultUsers);
    }).catch((error) => {
      console.log(error);
    });
  }

  showDetailView(user) {
    this.router.navigate(['/user-detail-view'], {
      queryParams: {
        id: user._id
      }
    });
  }

}
